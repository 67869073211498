<style scoped>
.footer-social-icon .fa {
    margin: inherit !important;
}
</style>
<template>
    <footer class="mainFooter bodyBG">
        <div class="container-fluid">
            <div class="row justify-content-between">
                <!-- <div class="col-12 col-lg-3">
               <div class="content">
                   <h6 class="d-lg-none" v-on:click="isHidden1 = !isHidden1" v-bind:class="[{ active: isHidden1 }]">Blog</h6>
                   <ul class="footerList">
                       <li><router-link to="/register">Register</router-link></li>
                       <li><a href="javascript:void(0)">Supported Brokers</a></li>
                       <li><a href="javascript:void(0)">Blog</a></li>
                       <li><a href="javascript:void(0)">Forum</a></li>
                   </ul>
               </div>
               </div> -->
                <div class="col-12 col-xl-4">
                    <div class="d-flex flex-column h-100 justify-content-between calBOxed">
                        <div class="socialFooter">
                            <div class="footerLogo flex-between align-items-start mb-3">
                                <img class="lightTheme" width="180" height="50" src="/assets/images/logo.svg"
                                    :alt="$t('footer.text23')" :title="$t('footer.text24')" />
                                <img class="darkTheme d-none" width="180" height="50" src="/assets/images/whiteLogo.svg"
                                    :alt="$t('footer.text23')" :title="$t('footer.text24')" />

                            </div>
                            <div
                                class="zulutradeCopyright menutextBG p-3 pb-0 d-flex align-items-start justify-content-between">
                                <div class="downloadApp">
                                    <p class="text-white f-20 mb-md-4 mb-2">{{ $t('footer.text2') }} <span
                                            class="hilighted fadebg">{{ $t('footer.text3') }}</span></p>
                                    <p class="f-12 mb-1 lighter text-white">{{ $t('footer.text4') }}</p>
                                    <div class="d-flex align-items-center">
                                        <a href="https://apps.apple.com/gr/app/zulutrade/id336913058" target="_blank"
                                            class="fadebg appStore text-white medium f-14 me-2">
                                            <img src="/assets/images/iosIcon.png" :alt="$t('footer.text25')"
                                                :title="$t('footer.text26')" />
                                            <span>{{ $t('login.ios') }}</span>
                                        </a>
                                        <a href="https://play.google.com/store/apps/details?id=zulu.trade.app"
                                            target="_blank" class="fadebg appStore text-white medium f-14">
                                            <img src="/assets/images/androidIcon.png" :alt="$t('footer.text27')"
                                                :title="$t('footer.text28')" />
                                            <span>{{ $t('login.android') }}</span>
                                        </a>
                                    </div>
                                </div>
                                <div class="mobileApp">
                                    <img class="d-block" src="/assets/images/zuluFooterImg.png"
                                        :alt="$t('footer.text29')" :title="$t('footer.text30')" />
                                </div>
                            </div>
                            <div class="rtSocial mt-4">
                                <p class="mb-2">{{ $t('footer.text1') }}</p>
                                <ul class="socialList d-flex align-items-center justify-content-start">
                                    <li>
                                        <a class="d-block ps-0" href="https://www.facebook.com/zulutrade/"
                                            target="_blank" :title="$t('footer.text31')"><i class="fa fa-facebook"
                                                aria-hidden="true"></i></a>
                                    </li>
                                    <li>
                                        <a class="d-block" href="https://twitter.com/ZuluTrade" target="_blank"
                                            :title="$t('footer.text32')"><i class="fa fa-twitter"
                                                aria-hidden="true"></i></a>
                                    </li>
                                    <li>
                                        <a class="d-block" href="https://www.linkedin.com/company/zulutrade"
                                            target="_blank" :title="$t('footer.text33')"><i class="fa fa-linkedin"
                                                aria-hidden="true"></i></a>
                                    </li>
                                    <li>
                                        <a class="d-block" href="https://www.youtube.com/user/zulutradechannel"
                                            target="_blank" title="$t('footer.text34')"><i class="fa fa-youtube-play"
                                                aria-hidden="true"></i></a>
                                    </li>
                                    <li>
                                        <a class="d-block" href="https://www.instagram.com/zulutrade/?hl=en"
                                            target="_blank">
                                            <img class="light" src="/assets/images/instagram.png"
                                                :alt="$t('footer.text35')" :title="$t('footer.text35')" width="24"
                                                height="24" />
                                            <img class="dark d-none" src="/assets/images/instagram-w.png"
                                                :alt="$t('footer.text35')" :title="$t('footer.text35')" width="24"
                                                height="24" />
                                        </a>
                                    </li>
                                    <li>
                                        <a class="d-block" href="https://www.tiktok.com/@zulutrade" target="_blank">
                                            <img class="light" src="/assets/images/tiktok.png"
                                                :alt="$t('footer.text36')" :title="$t('footer.text36')" width="24"
                                                height="24" />
                                            <img class="dark d-none" src="/assets/images/tiktok-w.png"
                                                :alt="$t('footer.text36')" :title="$t('footer.text36')" width="24"
                                                height="24" />
                                        </a>
                                    </li>
                                    <li>
                                        <a class="d-block" href="https://www.reddit.com/r/Zulu_Trade/" target="_blank">
                                            <img class="light" src="/assets/images/reddit.png"
                                                :alt="$t('footer.text37')" :title="$t('footer.text37')" width="24"
                                                height="24" />
                                            <img class="dark d-none" src="/assets/images/reddit-w.png"
                                                :alt="$t('footer.text37')" :title="$t('footer.text37')" width="24"
                                                height="24" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="copyRightZultarde" v-if="Object.keys(store.flavordata).length">
                            <p class="f-14 midgray mb-0" v-if="!store.flavordata?.isEu && appMode == 'WEBSITE'">
                                ©{{ $t('footer.globalCR') }} {{ COMMON.newYear() }} {{ $t('footer.footer_branding') }}
                            </p>
                            <p class="f-14 midgray mb-0" v-if="store.flavordata?.isEu && appMode == 'WEBSITE'">
                                ©{{ $t('footer.globalCR') }} {{ COMMON.newYear() }} {{ $t('footer.footer_branding_eu')
                                }}
                            </p>
                            <p class="f-14 midgray mb-0" v-if="appMode == 'COBRANDED'">©{{ $t('footer.globalCR') }} {{
                                        COMMON.newYear() }} {{ brandName() }}</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-xl-8">
                    <div class="row">
                        <div class="col-12 col-lg-4">
                            <div class="sndTop">
                                <h6 v-on:click="isHidden2 = !isHidden2" v-bind:class="[{ active: isHidden2 }]">
                                    {{ $t('footer.text5') }}</h6>
                                <ul class="footerList">
                                    <li>
                                        <!-- <a href="/assets/pdf/legal/TOS-EU.pdf" target="_blank" v-if="Object.keys(store.flavordata).length && store.flavordata.flavorId == 38">Terms Of Service</a>
                                        <a href="/assets/pdf/legal/TOS-EU.pdf" target="_blank" v-if="Object.keys(store.flavordata).length && store.flavordata.flavorId == 1">Terms Of Service</a> -->
                                        <router-link to="/terms-of-service">{{ $t('footer.text20') }}</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/privacy-policy">{{ $t('head.text18') }}</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/cookies-policy">{{ $t('privacy_policy.privacycontent15a')
                                            }}</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/risk-disclaimer">{{ $t('head.text19') }}</router-link>
                                    </li>
                                    <li>
                                        <!-- <a href="/assets/pdf/legal/Conflicts_Of_Interest_Policy_ZULUTRADE.pdf" target="_blank" v-if="Object.keys(store.flavordata).length && store.flavordata.flavorId == 38">Conflict of Interest Policy</a>
                                        <a href="/assets/pdf/legal/Conflicts_Of_Interest_Policy_ZULUTRADE.pdf" target="_blank" v-if="Object.keys(store.flavordata).length && store.flavordata.flavorId == 1">Conflict of Interest Policy</a> -->
                                        <router-link to="/conflict-of-interest-policy">{{ $t('footer.text6')
                                            }}</router-link>
                                    </li>
                                    <li>
                                        <!-- <a href="/assets/pdf/legal/Declaration_of_Consent_ZULUTRADE.pdf" target="_blank" v-if="Object.keys(store.flavordata).length && store.flavordata.flavorId == 38">Declaration of Consent</a>
                                        <a href="/assets/pdf/legal/Declaration_of_Consent_ZULUTRADE.pdf" target="_blank" v-if="Object.keys(store.flavordata).length && store.flavordata.flavorId == 1">Declaration of Consent</a> -->
                                        <router-link to="/declaration-of-consent">{{ $t('footer.text7') }}</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/eula">{{ $t('footer.text22') }}</router-link>
                                    </li>
                                </ul>
                            </div>
                            <div class="sndBottom">
                                <h6 v-on:click="isHidden3 = !isHidden3" v-bind:class="[{ active: isHidden3 }]">
                                    {{ $t('footer.text8') }}</h6>
                                <ul class="footerList">
                                    <!-- <li><router-link to="/automated-trading">Automator</router-link></li> -->
                                    <li>
                                        <router-link to="/autoprotect-your-account">{{ $t('footer.text9')
                                            }}</router-link>
                                    </li>
                                    <!-- <li><router-link to="/subscription">Subscription Plan</router-link></li> -->
                                </ul>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4">
                            <div class="sndTop">
                                <h6 v-on:click="isHidden4 = !isHidden4" v-bind:class="[{ active: isHidden4 }]">
                                    {{ $t('header.tool') }}</h6>
                                <ul class="footerList">
                                    <li>
                                        <router-link :to="{ name: 'calculator' }">{{ $t('header.text3') }}</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/calendar">{{ $t('header.text2') }}</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/pricing">{{ $t('header.text4') }}</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/trading-signals"> Trading Signals</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/forex-trading">Forex Trading</router-link>
                                    </li>
                                </ul>
                            </div>
                            <div class="sndBottom">
                                <h6 v-on:click="isHidden5 = !isHidden5" v-bind:class="[{ active: isHidden5 }]">
                                    {{ $t('footer.text10') }}</h6>
                                <ul class="footerList">
                                    <!-- <li>
                                        <router-link to="/blog">Blog</router-link>
                                    </li> -->
                                    <li>
                                        <router-link to="/affiliate-guide">{{ $t('header.affiliate') }}</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/leader-guide">{{ $t('footer.text11') }}</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/user-guide">{{ $t('footer.text12') }}</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/introducing-zulutrade">{{ $t('footer.text13') }}
                                        </router-link>
                                    </li>
                                    <li v-if="store.user.access_token">
                                        <router-link to="/feedback">{{ $t('footer.text14') }}</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/sitemap">{{ $t('footer.text15') }}</router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4">
                            <div class="sndTop">
                                <h6 v-on:click="isHidden6 = !isHidden6" v-bind:class="[{ active: isHidden6 }]">
                                    {{ $t('footer.text16') }}</h6>
                                <ul class="footerList">
                                    <li>
                                        <router-link to="/about">{{ $t('header.company') }} </router-link>
                                    </li>
                                    <!-- <li>
                                        <router-link to="/pricing">Pricing </router-link>
                                    </li> -->
                                    <!-- <li>
                                        <router-link to="/careers">Careers</router-link>
                                    </li> -->
                                </ul>
                            </div>
                            <div class="sndBottom">
                                <h6 v-on:click="isHidden7 = !isHidden7" v-bind:class="[{ active: isHidden7 }]">
                                    {{ $t('header.partner') }}</h6>
                                <ul class="footerList">
                                    <li>
                                        <router-link to="/become-a-leader">{{ $t('footer.text21') }}</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/become-an-affiliate">{{ $t('footer.text17') }}</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/partnership-program">{{ $t('footer.text18') }}</router-link>
                                    </li>
                                </ul>
                            </div>
                            <div class="sndBottom">
                                <h6 v-on:click="isHidden8 = !isHidden8" v-bind:class="[{ active: isHidden8 }]">
                                    {{ $t('footer.text38') }}</h6>
                                <ul class="footerList">
                                    <li>
                                        <a class="d-flex align-items-center" target="_blank"
                                            href="tel:+30 2130117499"><vue-feather size="16" class="me-2"
                                                type="phone-call"></vue-feather> +30 2130117499</a>
                                    </li>
                                    <li>
                                        <a class="d-flex align-items-center" target="_blank"
                                            href="mailto:support@zulutrade.com"><vue-feather size="16" class="me-2"
                                                type="mail"></vue-feather> support@zulutrade.com</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="disclamer mt-1" v-if="Object.keys(store.flavordata).length">
                                <p class="f-14 mb-2 medium darkbluecolor">{{ $t('footer.text19') }}</p>
                                <div v-if="!store.flavordata?.isEu">
                                    <div class="list-unstyled">
                                        <p>{{ $t('TOS.gText144') }}</p>
                                        <p>{{ $t('TOS.gText145') }} (<a href="https://zulutrade.com"
                                                class="secondary">https://zulutrade.com</a>) {{ $t('TOS.gText145a') }}</p>
                                        <!-- <p>{{$t('TOS.gText146')}}</p> -->
                                        <p>{{ $t('TOS.gText147') }}</p>
                                        <p>{{ $t('TOS.gText148') }}</p>
                                        <p>{{ $t('TOS.gText152') }} <router-link class="underline" to="/risk-disclaimer"
                                                style="color: #a16d2e;">{{ $t('TOS.gText153') }}</router-link></p>
                                    </div>
                                </div>
                                <div v-if="store.flavordata?.isEu">
                                    <!-- <p class="f-14 midgray">©{{$t('footer.euCR')}}</p> -->
                                    <!-- <ul class="list-unstyled">
                                        <p>{{$t('TOSEU.eText272')}}</p>
                                        <p>{{$t('TOSEU.eText273')}}</p>
                                        <ul class="dotList mb-2 ms-3">
                                            <li>{{$t('TOSEU.eText274')}}</li>
                                            <li>{{$t('TOSEU.eText275')}}</li>
                                            <li>{{$t('TOSEU.eText276')}}</li>
                                            <li>{{$t('TOSEU.eText277')}}</li>
                                        </ul>
                                        <p>{{$t('TOSEU.eText278')}}</p>
                                        <p>{{$t('TOSEU.eText279')}}</p>
                                        <p>{{$t('TOSEU.eText280')}} <router-link class="underline" to="/risk-disclaimer" style="color: #a16d2e;">{{$t('TOSEU.eText281')}}</router-link></p>
                                    </ul> -->
                                    <div class="list-unstyled">
                                        <p>{{ $t('TOS.gText144') }}</p>
                                        <p>{{ $t('TOS.gText145') }} (<a href="https://zulutrade.com"
                                                class="secondary">https://zulutrade.com</a>) {{ $t('TOS.gText145a') }}</p>
                                        <!-- <p>{{$t('TOS.gText146')}}</p> -->
                                        <p>{{ $t('TOS.gText147') }}</p>
                                        <p>{{ $t('TOS.gText148') }}</p>
                                        <p>{{ $t('TOS.gText152') }} <router-link class="underline" to="/risk-disclaimer"
                                                style="color: #a16d2e;">{{ $t('TOS.gText153') }}</router-link></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="position-fixed advertisingCookies d-flex align-items-center justify-content-between"
                v-if="showCookiesConsent">
                <div class="cookiesContent">
                    <h4>We respect your privacy!</h4>
                    <p>We need some cookies for our site to function, these are called “<span
                            class="secondary">essential</span>” cookies.</p>
                    <!-- For screen only for SM -->
                    <div class="d-block d-sm-none mb-2">
                        <p :class="[{ 'd-none': !readMoreLess }]">We'd also like to use “<span
                                class="secondary">analytics
                                and performance</span>” cookies and “<span class="secondary">advertising
                                cookies</span>”. You can control our use of these cookies in the consent banner below.
                            Accepting all cookies enables us to enhance your experience further. Please note, some of
                            these may be third-party cookies. You can modify your cookie preferences by clicking the
                            button below. For more information, please see our <a class="secondary underline"
                                href="/cookies-policy"> Cookie Policy</a>.</p>
                        <span class="secondary" @click="readMoreLess = !readMoreLess">{{ readMoreLess ? 'Read less' :
                                        'Read more' }}</span>
                    </div>
                    <!-- For screen's more than SM -->
                    <p class="d-none d-sm-block">We'd also like to use “<span class="secondary">analytics and
                            performance</span>” cookies and “<span class="secondary">advertising cookies</span>”. You
                        can control our use of these cookies in the consent banner below. Accepting all cookies enables
                        us to enhance your experience further. Please note, some of these may be third-party cookies.
                        You can modify your cookie preferences by clicking the button below. For more information,
                        please see our <a class="secondary underline" href="/cookies-policy"> Cookie Policy</a>.</p>
                </div>
                <div class="d-flex align-items-center flex-shrink-0 flex-nowrap equalButton">
                    <a data-bs-toggle="modal" data-bs-target="#modifyPreferencesModal"
                        class="button border-button zulu_btn rounded px-ms-4 me-3" rel="nofollow"
                        href="javascript:void(0)">Modify Preferences</a>
                    <a class="button zulu_btn rounded fillBtn px-ms-4" rel="nofollow" href="javascript:void(0)"
                        @click="setGDPRConsent('accept_all')">Accept All</a>
                </div>
            </div>
        </div>
        <!-- Modal Fot Cookies -->
        <div class="fade modal" id="modifyPreferencesModal" data-bs-backdrop="static" tabindex="-1"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
                        <h4 class="mb-3">Why are we using cookies?</h4>
                        <p>We use cookies to help you navigate efficiently and perform certain functions. You will find
                            detailed information about all cookies under each consent category below.</p>
                        <div class="usingCookies">
                            <div class="accordion accordion-flush" id="accordionFlushExample">
                                <div class="accordion-item d-flex align-items-start border-0 mb-4 pb-2">
                                    <label class="switch me-3 mt-2 flex-shrink-0 essential">
                                        <input type="checkbox" name="essential" v-model="essentialCokies" disabled>
                                        <span class="slider round"></span>
                                    </label>
                                    <div class="w-100">
                                        <h2 class="accordion-header" id="flush-headingOne">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"
                                                aria-expanded="false" aria-controls="flush-collapseOne">
                                                <p class="mb-0 bold">Essential cookies</p>
                                            </button>
                                        </h2>
                                        <div id="flush-collapseOne" class="accordion-collapse collapse"
                                            aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body pb-0 pt-2 ps-0">
                                                <p class="mb-0">These are required to enable the basic features of this
                                                    site, such as providing secure log-in or adjusting your consent
                                                    preferences. These cookies do not store any personally identifiable
                                                    data.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item d-flex align-items-start border-0 mb-4 pb-2">
                                    <label class="switch me-3 mt-2 flex-shrink-0">
                                        <input type="checkbox" name="analytics" v-model="analyticsCookies">
                                        <span class="slider round"></span>
                                    </label>
                                    <div class="w-100">
                                        <h2 class="accordion-header" id="flush-headingTwo">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo"
                                                aria-expanded="false" aria-controls="flush-collapseTwo">
                                                <p class="mb-0 bold">Analytics</p>
                                            </button>
                                        </h2>
                                        <div id="flush-collapseTwo" class="accordion-collapse collapse"
                                            aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body pb-0 pt-2 ps-0">
                                                <p class="mb-0">On this website, we use cookies to measure our audience,
                                                    analyse website data and improve your experience. You can select
                                                    here those you allow to stay.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item d-flex align-items-start border-0 mb-4 pb-2">
                                    <label class="switch me-3 mt-2 flex-shrink-0">
                                        <input type="checkbox" name="advertising" v-model="advertisingCookies">
                                        <span class="slider round"></span>
                                    </label>
                                    <div class="w-100">
                                        <h2 class="accordion-header" id="flush-headingThree">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#flush-collapseThree"
                                                aria-expanded="false" aria-controls="flush-collapseThree">
                                                <p class="mb-0 bold">Advertising</p>
                                            </button>
                                        </h2>
                                        <div id="flush-collapseThree" class="accordion-collapse collapse"
                                            aria-labelledby="flush-headingThree"
                                            data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body pb-0 pt-2 ps-0">
                                                <p class="mb-0">On this website, we use cookies to measure our audience,
                                                    nurture our relationship with you and send you from time to time
                                                    some quality content and some advertisement. You can select here
                                                    those you allow to stay.</p>
                                                <label
                                                    class="switch d-flex align-items-center mt-4 toggleFB flex-shrink-0">
                                                    <input type="checkbox" name="facebook" v-model="facebookCookies">
                                                    <span class="slider round"></span>
                                                    <span class="text bold">Facebook Pixel</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex align-items-center flex-shrink-0 equalButton">
                            <a class="button border-button zulu_btn rounded px-ms-4 me-3" rel="nofollow"
                                href="javascript:void(0)" @click="setGDPRConsent('Reject')">Reject All</a>
                            <a class="button border-button zulu_btn rounded px-ms-4 me-3" rel="nofollow"
                                href="javascript:void(0)" @click="setGDPRConsent('Save')">Save Preferences</a>
                            <a class="button zulu_btn rounded fillBtn px-ms-4" rel="nofollow" href="javascript:void(0)"
                                @click="setGDPRConsent('accept_all')">Accept All</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
import { myStore } from "@/store/pinia-store";
export default {
    setup() {
        const store = myStore();
        return { store };
    },
    data() {
        return {
            isHidden1: false,
            isHidden2: false,
            isHidden3: false,
            isHidden4: false,
            isHidden5: false,
            isHidden6: false,
            isHidden7: false,
            isHidden8: false,
            gtmId: process.env.VUE_APP_GTM_ID,
            essentialCokies: true,
            analyticsCookies: false,
            advertisingCookies: false,
            facebookCookies: false,
            showCookiesConsent: false,
            appMode: process.env.VUE_APP_MODE,
            readMoreLess: false
        };
    },
    methods: {
        hideModal() {
            let modal = document.getElementById('modifyPreferencesModal');
            modal.style.display = "none";
            document.body.classList.remove('modal-open');
            const backdrop = document.querySelector('.modal-backdrop');
            if (backdrop) {
                backdrop.remove();
            }
        },
        gtag() { window.dataLayer.push(arguments) },
        brandName() {
            return this.store.flavordata?.flavor || 'ZuluTrade'
        },
        setGDPRConsent(type) {
            try {
                const consentJson = {
                    'ad_storage': 'denied',
                    'ad_user_data': 'denied',
                    'ad_personalization': 'denied',
                    'analytics_storage': 'denied',
                    'functionality_storage': 'granted'
                };
                if (localStorage.getItem("consent") === null) {
                    if (type === 'accept_all') {
                        Object.keys(consentJson).forEach(key => {
                            consentJson[key] = 'granted';
                        });
                        window.fbq('consent', 'grant');
                    } else if (type === 'Reject') {
                        this.analyticsCookies = false;
                        this.advertisingCookies = false;
                        window.fbq('consent', 'revoke');
                    } else {
                        if (this.analyticsCookies) {
                            consentJson['analytics_storage'] = 'granted';
                        }
                        if (this.advertisingCookies) {
                            consentJson['ad_storage'] = 'granted';
                            consentJson['ad_user_data'] = 'granted';
                            consentJson['ad_personalization'] = 'granted';
                        }
                        if (this.facebookCookies) {
                            window.fbq('consent', 'grant');
                        }
                    }
                    this.gtag('consent', 'update', consentJson);
                    var gtagScript = document.createElement('script');
                    gtagScript.async = true;
                    gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${this.gtmId}`;
                    var firstScript = document.getElementsByTagName('script')[0];
                    firstScript.parentNode.insertBefore(gtagScript, firstScript);
                    localStorage.setItem("consent", JSON.stringify(consentJson));
                    this.showCookiesConsent = false
                    this.hideModal()
                }
            } catch (e) {
                console.log('e', e)
            }
        }
    },
    created() {
        if (localStorage.getItem("consent") === null) {
            const script = document.createElement('script');
            script.innerHTML = `
                    window.dataLayer = window.dataLayer || [];
                    function gtag() { dataLayer.push(arguments); }
                    gtag('js', new Date());
                    gtag('consent', 'default', {
                    'ad_user_data': 'denied',
                    'ad_personalization': 'denied',
                    'ad_storage': 'denied',
                    'analytics_storage': 'denied',
                    'functionality_storage': 'granted'
                    });
                    gtag('js', new Date());
                    gtag('config', '${this.gtmId}');
                `;
            document.head.appendChild(script);
            this.showCookiesConsent = true
        } else {
            this.showCookiesConsent = false
        }
    },
};
</script>
